import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { Link } from 'gatsby';
import linkResolver from 'helpers/linkResolver';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

import { SearchBar } from 'components/ui';

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shape: {
    position: 'absolute',
    background: theme.palette.primary.main,
    width: '100%',
    top: '-10px',
    left: 0,
    height: '100%',
    zIndex: 0,
    clipPath: 'ellipse(210% 77% at 50% 78%)',
    [theme.breakpoints.up('sm')]: {
      clipPath: 'ellipse(125% 78% at 50% 78%)',
    },
  },
  video: {
    width: '100%',
    clipPath: 'ellipse(210% 77% at 50% 78%)',
    [theme.breakpoints.up('sm')]: {
      clipPath: 'ellipse(125% 78% at 50% 78%)',
    },
    '&.border-bottom': {
      borderBottom: `10px solid ${theme.palette.primary.main}`,
    },
  },
  heroWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    zIndex: 100,
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: 0,
    height: '100%',
    zIndex: 50,
    clipPath: 'ellipse(210% 77% at 50% 78%)',
    [theme.breakpoints.up('sm')]: {
      clipPath: 'ellipse(125% 78% at 50% 78%)',
    },
  },
  menu: {
    position: 'absolute',
    zIndex: '100',
    bottom: '10px',
    background: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
  },
  mobileMenulinkItem: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
  },
  listItem: {
    listStyle: 'none',
  },
});

function HeroVideo(props) {
  const {
    isFront,
    videoUrl,
    mobileVideoUrl,
    title,
    strapline,
    overlayColor,
    overlayOpacity,
    menu,
    search,
    classes,
  } = props;

  const [showDesktopVideo, setShowDesktopVideo] = useState(true);
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    if (onlyWidth <= 600) {
      if (mobileVideoUrl) {
        setShowDesktopVideo(false);
      }
    }
    if (onlyWidth >= 601) {
      setShowDesktopVideo(true);
    }
  }, [onlyWidth]);

  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <Box position="relative">
        <div className={classes.wrapper}>
          <div className={classes.shape} />
          <div
            className={classes.overlay}
            style={{
              backgroundColor: overlayColor ? overlayColor : '#000000',
              opacity: overlayOpacity ? overlayOpacity : '0.2',
            }}
          />
          <div className={classes.heroWrapper}>
            {title && (
              <Box component="div" color="text.secondary" align="center" mb={2}>
                <RichText render={title} htmlSerializer={htmlSerializer} />
              </Box>
            )}
            {strapline && (
              <Box component="div" color="text.secondary" align="center">
                <RichText render={strapline} htmlSerializer={htmlSerializer} />
              </Box>
            )}
          </div>
          {showDesktopVideo && (
            <video
              className={`${classes.video} ${isFront ? 'border-bottom' : ''}`}
              controls={false}
              muted
              autoPlay
              loop
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          )}
          {!showDesktopVideo && mobileVideoUrl && (
            <video
              className={`${classes.video} ${isFront ? 'border-bottom' : ''}`}
              controls={false}
              muted
              autoPlay
              loop
            >
              <source src={mobileVideoUrl} type="video/mp4" />
            </video>
          )}
          <Box
            display={{ xs: 'flex', lg: 'none' }}
            className={classes.menu}
            px={1}
            py={2}
          >
            <Box
              component="ul"
              width="100%"
              justifyContent="space-around"
              m="0"
              p="0"
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              {menu.main_menu.map((item, key) => {
                return (
                  <Box key={key} component="li" className={classes.listItem}>
                    <Typography
                      component={Link}
                      variant="button"
                      to={`${item.link.type ? linkResolver(item.link) : ''}`}
                      className={classes.mobileMenulinkItem}
                      activeClassName="active"
                    >
                      {item.link_name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </div>
        {search && <SearchBar />}
      </Box>
    </Container>
  );
}

HeroVideo.propTypes = {
  isFront: PropTypes.bool,
  videoUrl: PropTypes.string.isRequired,
  mobileVideoUrl: PropTypes.string,
  title: PropTypes.array,
  strapline: PropTypes.array,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
  menu: PropTypes.object,
  search: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeroVideo);
