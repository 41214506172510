import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const GLobalSpacing = graphql`
  query {
    prismicGlobalLayout {
      data {
        default_spacer
      }
    }
  }
`;

function Spacer(props) {
  const { children } = props;
  return (
    <StaticQuery
      query={`${GLobalSpacing}`}
      render={(data) => (
        <Box pt={data.prismicGlobalLayout.data.default_spacer}>{children}</Box>
      )}
    />
  );
}

Spacer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Spacer;
