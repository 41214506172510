import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import axios from 'axios';
import Layout from 'components/layout';
import Spacer from 'components/spacer';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import HeroImage from 'components/hero/front/image';
import HeroVideo from 'components/hero/front/video';

import { DownloadsAndLinks, Sitemap, Availability } from 'components/slices';

import ResolveSlices from 'components/slices/ResolveSlices';
import { Fastfood } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

function Development(props) {
  const { data } = props;
  const doc = data.prismicDevelopment.data;
  if (!doc) return null;
  const doc_uid = data.prismicDevelopment.uid;
  //const prismicId = data.prismicDevelopment.prismicId;
  const videoUrl = '';

  const hasAvailability =
    doc.availability && doc.availability[0] && doc.availability[0].plot_name
      ? true
      : false;

  const showThirdBrochure = doc_uid == 'six-acres' ? true : false;

  return (
    <Layout>
      {!doc.display_mode ? (
        <React.Fragment>
          {doc.banner_image && doc.banner_image.url && (
            <HeroImage
              displayLogo={true}
              logoUrl={doc.development_logo.url}
              logoTitle={doc.title.text}
              logoLocation={doc.development_location.text}
              imageUrl={doc.banner_image.url}
              imageAlt={
                doc.banner_image.alt ? doc.banner_image.alt : doc.title.text
              }
              activeDevelopment={doc.archive_status === false ? false : true}
            />
          )}
        </React.Fragment>
      ) : (
        <HeroVideo isFront={true} videoUrl={videoUrl} data={doc} />
      )}
      {doc.body && <ResolveSlices data={doc.body} />}
      {doc.archive_status && (
        <Spacer>
          <DownloadsAndLinks
            brochure={doc.brochure}
            brochure_text={doc.brochure_text ? doc.brochure_text : ''}
            secondary_brochure={
              doc.secondary_brochure ? doc.secondary_brochure : ''
            }
            secondary_brochure_text={
              doc.secondary_brochure_text ? doc.secondary_brochure_text : ''
            }
            tour={doc.three_d_showhome_tour}
            schools={doc.local_schools}
            facilities={doc.area__facilities}
            doc_uid={doc_uid}
            third_brochure={showThirdBrochure}
            incentives={doc.incentives}
            development_video={doc.development_video}
            testimonial_video={doc.testimonial_video}
          />
        </Spacer>
      )}
      {doc.archive_status && (
        <Spacer>
          <Container maxWidth={process.env.CONTAINER_WIDTH}>
            <div className="spaced-grid-wrapper">
              <Grid container spacing={10}>
                {doc.sitemap && doc.sitemap.url && (
                  <Grid item xs={12} lg={hasAvailability ? 6 : 12}>
                    <Sitemap data={doc.sitemap} />
                  </Grid>
                )}
                {doc.availability &&
                  doc.availability[0] &&
                  doc.availability[0].plot_name && (
                    <Grid item xs={12} lg={6}>
                      <Availability
                        data={doc.availability}
                        show_availability_footer_message={
                          doc.show_availability_footer_message
                        }
                      />
                    </Grid>
                  )}
              </Grid>
            </div>
          </Container>
        </Spacer>
      )}
      <Box pt={10}>
        <Typography align="center" component="p" variant="body2">
          Some Imagery is indicative, contact us to find out more
        </Typography>
      </Box>
    </Layout>
  );
}

export default Development;

Development.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query DevelopmentPageQuery($id: String!) {
    prismicDevelopment(id: { eq: $id }) {
      uid
      prismicId
      data {
        archive_status
        display_mode
        banner_image {
          url
          alt
        }
        title {
          html
          raw
          text
        }
        development_location {
          html
          raw
          text
        }
        development_logo {
          url
          alt
        }
        area__facilities {
          url
          type
          link_type
          target
        }
        brochure {
          url
          type
          target
          link_type
        }
        incentives {
          url
          type
          target
          link_type
        }
        development_video {
          embed_url
        }
        testimonial_video {
          embed_url
        }
        brochure_text
        secondary_brochure {
          url
          type
          target
          link_type
        }
        secondary_brochure_text
        three_d_showhome_tour {
          url
          link_type
        }
        local_schools {
          url
          type
          target
          link_type
        }
        sitemap {
          alt
          url
        }
        availability {
          bedrooms
          floorplan {
            url
            uid
            type
          }
          ground_floor {
            url
          }
          first_floor {
            url
          }
          second_floor {
            url
          }
          plot_name
          plot_status_options
          price
        }
        show_availability_footer_message
        body {
          ... on PrismicDevelopmentDataBodyTitleAndText {
            slice_type
            primary {
              alignment
              content {
                html
                raw
                text
              }
              content_grid_width
              display_icon_below_title
              title {
                html
                raw
                text
              }
              title_text_color
            }
          }
          ... on PrismicDevelopmentDataBodyFullWidthImage {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              link {
                uid
                type
                id
              }
              image {
                url
                alt
              }
              content_layout_theme
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicDevelopmentDataBodyImageSlideshow {
            slice_type
            items {
              image {
                alt
                url
              }
            }
          }
          ... on PrismicDevelopmentDataBodyContentBlock {
            id
            slice_label
            slice_type
            primary {
              block_width
              content {
                html
                raw
                text
              }
            }
          }
        }
      }
    }
  }
`;
