import React from 'react';
import PropTypes from 'prop-types';
import Spacer from 'components/spacer';
import {
  TitleAndText,
  LatestDevelopments,
  LineSpacer,
  ThreeColImageGrid,
  FullWidthImage,
  ColumnContentBlock,
  ImageSlideshow,
  AllDevelopments,
  ForthcomingDevelopments,
  ImageAndContentBlock,
  Video,
  ContentCarousel,
  ContentImagesGrid,
  MeetTheTeam,
  IconBulletList,
  ContactForm,
  ContactBlock,
  NewsListing,
  ContentBlock,
} from 'components/slices';

function ResolveSlices(props) {
  const { data } = props;
  return (
    <React.Fragment>
      {data.map((item, i) => {
        switch (item.slice_type) {
          case 'title_and_text':
            return (
              <Spacer key={i}>
                <TitleAndText data={item.primary} />
              </Spacer>
            );
          case 'latest_developments':
            return (
              <Spacer key={i}>
                <LatestDevelopments data={item.primary} items={item.items} />
              </Spacer>
            );
          case 'line_spacer':
            return (
              <Spacer key={i}>
                <LineSpacer data={item.primary} />
              </Spacer>
            );
          case '1_3_image_grid':
            return (
              <Spacer key={i}>
                <ThreeColImageGrid data={item.primary} items={item.items} />
              </Spacer>
            );
          case 'full_width_image':
            return (
              <Spacer key={i}>
                <FullWidthImage data={item.primary} />
              </Spacer>
            );
          case '50_50_column_content_block':
            return (
              <Spacer key={i}>
                <ColumnContentBlock data={item.primary} items={item.items} />
              </Spacer>
            );
          case 'image_slideshow':
            return (
              <Spacer key={i}>
                <ImageSlideshow items={item.items} />
              </Spacer>
            );
          case 'all_developments':
            return (
              <Spacer key={i}>
                <AllDevelopments data={item.primary} items={item.items} />
              </Spacer>
            );
          case 'forthcoming_developments':
            return (
              <Spacer key={i}>
                <ForthcomingDevelopments items={item.items} />
              </Spacer>
            );
          case 'image_and_content_block':
            return (
              <Spacer key={i}>
                <ImageAndContentBlock data={item.primary} />
              </Spacer>
            );
          case 'video':
            return (
              <Spacer key={i}>
                <Video data={item.primary} />
              </Spacer>
            );
          case 'content_carousel':
            return (
              <Spacer key={i}>
                <ContentCarousel items={item.items} />
              </Spacer>
            );
          case '1_3_content_2_3_images':
            return (
              <Spacer key={i}>
                <ContentImagesGrid data={item.primary} items={item.items} />
              </Spacer>
            );
          case 'meet_the_team':
            return (
              <Spacer key={i}>
                <MeetTheTeam data={item.primary} items={item.items} />
              </Spacer>
            );
          case 'icon_bullet_list':
            return (
              <Spacer key={i}>
                <IconBulletList data={item.primary} items={item.items} />
              </Spacer>
            );
          case 'contact_form':
            return (
              <Spacer key={i}>
                <ContactForm data={item.primary} />
              </Spacer>
            );
          case 'contact_block':
            return (
              <Spacer key={i}>
                <ContactBlock data={item.items} />
              </Spacer>
            );
          case 'news_listing':
            return (
              <Spacer key={i}>
                <NewsListing data={item.primary} />
              </Spacer>
            );
          case 'content_block':
            return (
              <Spacer key={i}>
                <ContentBlock data={item.primary} />
              </Spacer>
            );

          default:
            return null;
        }
      })}
    </React.Fragment>
  );
}

ResolveSlices.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ResolveSlices;
