import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RectoryIdent from 'images/RectoryIdent.svg';

import { useWindowWidth } from '@react-hook/window-size/throttled';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shape: {
    position: 'absolute',
    background: theme.palette.primary.main,
    width: '100%',
    top: '-10px',
    left: 0,
    height: '100%',
    zIndex: 0,
    clipPath: 'ellipse(210% 77% at 50% 78%)',
    [theme.breakpoints.up('sm')]: {
      clipPath: 'ellipse(125% 78% at 50% 78%)',
    },
  },
  image: {
    width: '100%',
    clipPath: 'ellipse(210% 77% at 50% 78%)',
    [theme.breakpoints.up('sm')]: {
      clipPath: 'ellipse(125% 78% at 50% 78%)',
    },
    '&.border-bottom': {
      borderBottom: `10px solid ${theme.palette.primary.main}`,
    },
  },
  heroWrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: 100,
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    left: 0,
    height: '100%',
    zIndex: 50,
    clipPath: 'ellipse(210% 77% at 50% 78%)',
    [theme.breakpoints.up('sm')]: {
      clipPath: 'ellipse(125% 78% at 50% 78%)',
    },
  },
  logoOverlay: {
    left: '50%;',
    bottom: '0%',
    zIndex: 50,
    transform: 'translate(-50%, 50%)',
  },
  logoWrapper: {
    border: `2px solid ${theme.palette.text.primary}`,
  },
  logo: {
    minWidth: '60px',
    maxWidth: '200px',
    display: 'block',
    margin: '0 auto',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

function HeroImage(props) {
  const {
    isFront,
    displayLogo,
    imageUrl,
    imageAlt,
    title,
    strapline,
    overlayColor,
    overlayOpacity,
    logoUrl,
    logoTitle,
    logoLocation,
    activeDevelopment,
    classes,
  } = props;
  const wrapperBtmMargin = displayLogo ? 12 : 0;

  const [aspectRatio, setAspectRatio] = useState('16:8');
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    if (onlyWidth <= 600) {
      setAspectRatio('1:1.4');
    }
    if (onlyWidth >= 601) {
      setAspectRatio('16:8');
    }
  }, [onlyWidth]);

  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <Box className={classes.wrapper} mb={wrapperBtmMargin}>
        <div className={classes.shape} />
        <div
          className={classes.overlay}
          style={{
            backgroundColor: overlayColor ? overlayColor : '#000000',
            opacity: overlayOpacity ? overlayOpacity : '0.2',
          }}
        />
        <div className={classes.heroWrapper}>
          {title && (
            <Box component="div" color="text.secondary" align="center" mb={2}>
              <RichText render={title} htmlSerializer={htmlSerializer} />
            </Box>
          )}
          {strapline && (
            <Box component="div" color="text.secondary" align="center">
              <RichText render={strapline} htmlSerializer={htmlSerializer} />
            </Box>
          )}
        </div>
        <img
          src={`${imageUrl}&ar=${aspectRatio}&fit=crop`}
          alt={imageAlt}
          className={`${classes.image} ${isFront ? 'border-bottom' : ''}`}
        />
        {displayLogo && (
          <Box position="absolute" className={classes.logoOverlay}>
            <Box
              bgcolor="secondary.main"
              py={4}
              px={3}
              className={classes.logoWrapper}
            >
              {logoUrl && activeDevelopment && (
                <Box mb={2}>
                  <img
                    src={logoUrl}
                    alt={title ? title : ''}
                    className={classes.logo}
                  />
                </Box>
              )}
              {!activeDevelopment && (
                <Box mb={2} display="flex" justifyContent="center">
                  <RectoryIdent />
                </Box>
              )}
              {logoTitle && (
                <Box>
                  <Typography
                    variant="h5"
                    component="p"
                    align="center"
                    className={classes.uppercase}
                  >
                    {logoTitle}
                  </Typography>
                </Box>
              )}
              {logoLocation && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    align="center"
                    className={classes.uppercase}
                  >
                    {logoLocation}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
}

HeroImage.propTypes = {
  isFront: PropTypes.bool,
  displayLogo: PropTypes.bool,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  title: PropTypes.object,
  strapline: PropTypes.object,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.string,
  logoUrl: PropTypes.string,
  logoTitle: PropTypes.string,
  logoLocation: PropTypes.string,
  activeDevelopment: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeroImage);
